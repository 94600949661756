(function ($) {
    'use strict';

    // Feature detect + local reference
    var storage = (function() {
        var uid = new Date;
        var storage;
        var result;
        try {
            (storage = window.localStorage).setItem(uid, uid);
            result = storage.getItem(uid) == uid;
            storage.removeItem(uid);
            return result && storage;
        } catch (exception) {}
    }());

    var unsaved = false,
        submitted = false,
        timer = 0,
        resetTimer = 0,
        icons = 'fa-spin fa-sticky-note-o fa-check fa-circle-o-notch',
        $noteBlock = $('[data-noteblock]'),
        $noteRef = $('[data-notereference]'),
        $jsNoteSaved = $('.js-note__saved'),
        $jsNoteSavedIcon = $jsNoteSaved.children('span.fa'),
        $jsNoteSavedMessage = $jsNoteSaved.children('span.msg'),
        savedData = storage.getItem($noteBlock.data('noteblock')),
        savedRef = storage.getItem($noteRef.data('notereference'));

    if (savedData !== null && $noteBlock.val() == '') {
        $noteBlock.val(savedData);
    };

    if (savedRef !== null && $noteRef.val() == '' && savedData !== '') {
        $noteRef.val(savedRef);
    };

    $noteBlock.add($noteRef).on('change keyup', function() {
        if ($(this).is($noteRef) && $noteBlock.val() == '') {
            return;
        }

        $jsNoteSavedIcon.removeClass(icons).addClass('fa-circle-o-notch fa-spin');
        $jsNoteSaved.removeClass('bg-success text-success').addClass('text-muted');
        $jsNoteSavedMessage.text('…');

        clearTimeout(timer);
        clearTimeout(resetTimer);

        timer = window.setTimeout(function(){
            unsaved = true;
            storeData();
            indicateStatus();
        }, 1000);
    });

    window.addEventListener('beforeunload', function () {
        if (unsaved && !submitted) {
            if (storeData() === false) {
                return 'You have not saved this note, are you sure you wish to discard it?';
            }
        }
    });

    $noteBlock.closest('form').on('submit', function(){
        submitted = true;
        storage.removeItem($noteBlock.data('noteblock'));
        storage.removeItem($noteRef.data('notereference'));
        clearTimeout(timer);
        clearTimeout(resetTimer);
    });

    function storeData() {
        if (unsaved && storage) {
            storage.setItem(
                $noteBlock.data('noteblock'),
                $noteBlock.val()
            );
            storage.setItem(
                $noteRef.data('notereference'),
                $noteRef.val()
            );
            return true;
        }
        return false;
    };

    function indicateStatus() {
        if (!storage) {
            return;
        }

        $jsNoteSaved.toggleClass('bg-success text-success', unsaved).toggleClass('text-muted', !unsaved);

        if (unsaved) {
            $jsNoteSavedIcon.removeClass(icons).addClass('fa-check');
            $jsNoteSavedMessage.text('Draft saved');
            unsaved = false;
            resetTimer = window.setTimeout(indicateStatus, 2000);
            return;
        }

        if (!unsaved) {
            $jsNoteSavedIcon.removeClass(icons).addClass('fa-sticky-note-o');
            $jsNoteSavedMessage.text('No changes since last draft save');
            return;
        }
    };
})(jQuery);

(function ($) {
    'use strict';

    function calculateSumTotals($sum, $total) {
        var total = 0;
        $sum.each(function () {
            var value = parseInt(this.value, 10);
            if (!isNaN(value)) {
                total = total + value;
            }
        });
        $total.text(total);
    }

    var $total = $('.js-sum-total__total');
    var $sum = $('.js-sum-total__sum');

    if ($sum.length) {
        $sum.on('keyup blur', function () {
            calculateSumTotals($sum, $total);
        });
        calculateSumTotals($sum, $total);
    }

})(jQuery);

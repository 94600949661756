(function ($) {
    'use strict';
    function DisableFields($tick, elements) {
        this.elements = elements;
        this.$tick = $tick;
        this.toggle();
        this.setStates();
    }

    DisableFields.prototype.toggle = function() {
        var that = this;
        this.$tick.on('click', function() {
            that.setStates();
        });
    };

    DisableFields.prototype.setStates = function() {
        var that = this;
        if (this.$tick.is(':checked')) {
            $.each(that.elements, function (index, value) {
                $(value).prop('disabled', true);
            });
        } else {
            $.each(that.elements, function (index, value) {
                $(value).prop('disabled', false);
            });
        }
    };

    module.exports = DisableFields;
})(jQuery);

(function ($) {
    'use strict';

    function CostPerPortion($quantity, $unit, $cost, $result, $cartonNotice) {
        this.$quantity = $quantity;
        this.quantity = $quantity.val();
        this.$unit = $unit;
        this.unit = $unit.filter(':checked').val();
        this.$cost = $cost;
        this.cost = $cost.val();
        this.$result = $result;
        this.$cartonNotice = $cartonNotice;
        this.toggleCartonNotice();

        var that = this;
        $quantity.on('keyup blur', function () {
            that.unit = that.$unit.filter(':checked').val();
            that.cost = that.$cost.val();
            that.quantity = $(this).val();
            that.calculate();
        });
        $unit.on('change', function () {
            that.unit = that.$unit.filter(':checked').val();
            that.quantity = that.$quantity.val();
            that.cost = that.$cost.val();
            that.calculate();
            that.toggleCartonNotice();
        });
        $cost.on('keyup blur', function () {
            that.unit = that.$unit.filter(':checked').val();
            that.quantity = that.$quantity.val();
            that.cost = $(this).val();
            that.calculate();
        });
        $.each(this.$unit, function(index, value) {
            if ($(value).is(':checked')) {
                that.unit = $(value).val();
            }
        });
        this.calculate();
    }

    CostPerPortion.prototype.calculate = function () {
        var result = 0;
        switch (this.unit) {
            case 'LITRE':
                result = this.cost / (this.quantity / 0.189);
                break;
            case 'PINTS':
                result = this.cost / (this.quantity * 3);
                break;
            case 'CARTONS':
                result = this.cost / this.quantity;
                break;
            case 'TIN_SMALL':
                result = this.cost / (this.quantity * 15);
                break;
            case 'TIN_LARGE':
                result = this.cost / (this.quantity * 30);
                break;
        }

        if ($.isNumeric(result)) {
            result = result.toFixed(2);
        } else {
            result = '0.00';
        }
        this.$result.text(this.$result.data('message').replace('%amount%', result));
    };

    CostPerPortion.prototype.toggleCartonNotice = function() {
        if (this.$cartonNotice.length > 0) {
            this.unit == 'CARTONS' ? this.$cartonNotice.show() : this.$cartonNotice.hide();
        }
    };

    $('.js-purchase-cost').each(function () {
        const $this = $(this);
        const $quantity = $this.find('.js-purchase-cost__quantity');
        const $unit = $this.find('.js-purchase-cost__unit input[type=radio]');
        const $cost = $this.find('.js-purchase-cost__cost');
        const $result = $this.find('.js-purchase-cost__result');
        const $cartonNotice = $this.find('.js-carton-notice');
        new CostPerPortion($quantity, $unit, $cost, $result, $cartonNotice);
    });
})(jQuery);

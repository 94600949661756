(function ($) {
    'use strict';

    var $input = $('.js-note-attachment__file');

    $input.on('change', function(){
        var $this = $(this);
        var filename = $this.val().split('\\').pop();

        $this.prev('.js-note-attachment__filename').text(filename);
        $this.parent('form').find('.js-note-attachment__submit').toggleClass('btn-default btn-primary');
    });

    if (window.location.hash && $('ul.timeline').length) {
        var $el = $(window.location.hash);
        $el.addClass('note-attachment-highlight');
        $('html, body').animate({
            scrollTop: $el.offset().top
        }, 500);
    }

    $('.js-note-attachment__remove').on('click', function(e){
        e.preventDefault();
        var $this = $(this);

        $this.toggleClass('btn-default btn-danger').children('i').toggleClass('text-danger');
        $($this.data('target')).toggleClass('hide');
    });
})(jQuery);

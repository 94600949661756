(function ($) {

    $('.js-missing-period').on('click', function (e) {
        $('#claim_period_startDate_day').val($(this).data('startday'));
        $('#claim_period_startDate_month').val($(this).data('startmonth'));
        $('#claim_period_startDate_year').val($(this).data('startyear'));
        $('#claim_period_endDate_day').val($(this).data('endday'));
        $('#claim_period_endDate_month').val($(this).data('endmonth'));
        $('#claim_period_endDate_year').val($(this).data('endyear'));
    });

})(jQuery);

(function ($, storage) {
    'use strict';

    // Only check for the current state of the sidebar if we're in desktop view.
    $('body').toggleClass('sidebar-collapse',
        storage.getItem('sidebar-toggle-collapsed') === '1' && $(window).width() > 767
    );

    $('.sidebar-toggle').on('click', function() {
        storage.setItem('sidebar-toggle-collapsed',
            storage.getItem('sidebar-toggle-collapsed') === '1' ? '' : '1'
        );
    });
})(jQuery, window.sessionStorage);

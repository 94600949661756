(function ($) {
    'use strict';

    var $modal,
        $modalContentsHolder;

    $modal = $('.js-claim__modal');

    if ($modal.length === 0) {
        return;
    }

    $modalContentsHolder = $modal.find('.modal-contents__holder');

    $('.js-claim__launch-modal').on('click', function (e) {
        e.preventDefault();
        var $this = $(this),
            $content = $this.next('.modal-contents');

        $modalContentsHolder.empty().append($content.clone().removeClass('hide'));

        $modal.modal();
    });
})(jQuery);

(function ($) {
    'use strict';

    var $suspendRestore = $('.js-dossier__suspend-restore');
    var $add = $('.js-dossier__also-add');
    var $addAlert = $('.js-dossier__also-add--alert');

    $suspendRestore.on('click', function (e) {
        var state = $(this).is(':checked');

        $add.prop('checked', state)
            .toggleClass('disabled', state)
            .prop('disabled', state);

        $add.parent('label').toggleClass('text-muted', state);

        $addAlert.toggleClass('in', state);
    });
})(jQuery);

const DisableFields = require('./DisableFields');

(function ($, DisableFields) {
    'use strict';
    var settingEditSameAddress = $('#setting_basic_details_sameAddress');
    if (settingEditSameAddress.length) {
        new DisableFields(settingEditSameAddress, [
            '.js-setting_basic_details_correspondenceAddress-entry',
            '.js-setting_basic_details_correspondenceAddress-lookup',
            '#setting_basic_details_correspondenceAddress_line1',
            '#setting_basic_details_correspondenceAddress_line2',
            '#setting_basic_details_correspondenceAddress_line3',
            '#setting_basic_details_correspondenceAddress_town',
            '#setting_basic_details_correspondenceAddress_postcode'
        ]);
    }

})(jQuery, DisableFields);

(function ($) {
    'use strict';

    var $endDateInputs = $('.js-end-date input');
    var $endDateToggle = $('.js-no-end-date');

    // Save the initial values
    $.each($endDateInputs, function() {
        $(this).data('saved-value', $(this).val());
    });

    // Disable inputs if no end date is set
    if ($endDateToggle.is(':checked')) {
        toggleInputs();
    }
        
    $endDateToggle.on('change', function() {
        toggleInputs();
    });

    function toggleInputs() {
        if ($endDateToggle.is(':checked')) {
            $.each($endDateInputs, function() {
                disable($(this));
            });
        } else {
            $.each($endDateInputs, function() {
                enable($(this));
            });
        }
    }

    function disable($element) {
        $element.prop('disabled', true);
        $element.val('');
    }

    function enable($element) {
        $element.prop('disabled', false);
        $element.val($element.data('saved-value'));
    }

})(jQuery);

/**
 * A class to show a single element based on the selection from a select or radio input.
 *
 * Form elements inside the hidden containers will be disabled to ensure HTML5
 * validation errors from hidden form elements do not block the form submission.
 *
 * The values param is an array of an objects like:
 *     {'value': 'the value of the form input selected', 'element' : 'jQuery selector of the element to show if selected'}
 *
 * An example of this is:
 *     new ChangeViewFromChoice('#setting_milk_milkProvider', [
 *         {'value': 'SELF_PURCHASE', 'element' : '#self-purchase'},
 *         {'value': 'AGENT', 'element' : '#agent-purchase'},
 *     ]);
 *
 * @param selectInput The jQuery selector of the form input ".form-input"
 * @param values      Array of objects with value and name properties
 * @constructor
 */
(function ($) {
    'use strict';

    // Mark which form fields were disabled by this JS so it doesn't enable
    // ones that are supposed to remain disabled.
    const DISABLED_ATTR_MARKER_CLASS = 'js-ChangeViewFromChoice-enable';

    // Which form elements should get a disabled attribute added, adding the disabled
    // attribute prevents any HTML validation errors from blocking submission.
    const FORM_ELEMENTS_SELECTOR = 'input, select, button';

    function ChangeViewFromChoice(selectInput, values) {
        this.$selectInput = $(selectInput);
        this.values = values;

        var that = this;
        this.$selectInput.on('change', function() {
            that.hideAll();
            that.showOne();
        });
        this.hideAll(); //if there is a new page load, show the one already selected.
        this.showOne();
    }

    ChangeViewFromChoice.prototype.showOne = function() {
        for (var i = 0; i < this.values.length; i++) {
            if (this.$selectInput.attr('type') == 'radio') {
                if (this.values[i]['value'] == this.$selectInput.filter(':checked').val()) {
                    showElement($(this.values[i]['element']));
                }
            } else {
                if (this.values[i]['value'] == this.$selectInput.val()) {
                    showElement($(this.values[i]['element']));
                }
            }
        }
    };

    ChangeViewFromChoice.prototype.hideAll = function() {
        for (var i = 0; i < this.values.length; i++) {
            hideElement($(this.values[i]['element']));
        }
    };

    function hideElement($container) {
        $container.find(FORM_ELEMENTS_SELECTOR).each(function () {
            $(this)
                .attr('disabled', true)
                .addClass(DISABLED_ATTR_MARKER_CLASS);
        });

        $container.addClass('hide');
    }

    function showElement($container) {
        $container.find('.'+DISABLED_ATTR_MARKER_CLASS).each(function () {
            $(this)
                .removeAttr('disabled')
                .removeClass(DISABLED_ATTR_MARKER_CLASS);
        });

        $container.removeClass('hide');
    }

    module.exports = ChangeViewFromChoice;
})(jQuery);

(function (localStorage) {
    'use strict';

    var itemName = 'cowHide';
    var hideClassName = 'birthday';
    var elements;

    function updateClass() {
        if (parseInt(localStorage.getItem(itemName), 10)) {
            document.body.classList.add(hideClassName);
        } else {
            document.body.classList.remove(hideClassName);
        }
    }

    function toggle() {
        if (parseInt(localStorage.getItem(itemName), 10)) {
            localStorage.setItem(itemName, 0);
        } else {
            localStorage.setItem(itemName, 1);
        }
    }

    function init() {
        if (localStorage.getItem(itemName) === null) {
            localStorage.setItem(itemName, document.body.classList.contains(hideClassName) ? 1 : 0);
        }

        updateClass();
    }

    try {
        elements = document.getElementsByClassName('js-cow-hide-toggle');

        if (elements.length === 0) {
            return;
        }

        init();

        elements[0].addEventListener('click', function(e) {
            e.preventDefault();

            toggle();
            updateClass();
        });
    } catch (e) {
        // Avoid breaking rest of the JS for a bit of fun.
        if (console && console.error) {
            console.error(e);
        }
    }
})(window.localStorage || {});

/**
 * Prevent double form submissions.
 *
 * This operates under some assumptions:
 *
 * 1. The form is a full page submission and triggers a redirect on success and
 *    page load on failure.
 * 2. You only want to allow one protected form to be submitted.
 * 3. A response is returned within 5 seconds.
 */
(function ($) {
    'use strict';

    var submitting = false;

    $(document).on('submit', '.js-form__disable-on-submit', function (e) {
        var $form = $(this),
            $buttons = $form.find('button[type=submit]:enabled');

        $buttons.prop('disabled', true);

        if (submitting) {
            e.preventDefault();

            return;
        }

        submitting = true;

        // Set timer to remove guard flag incase something goes wrong.
        setTimeout(function () {
            submitting = false;
            $buttons.prop('disabled', false);
        }, 1000 * 5);
    });
})(jQuery);

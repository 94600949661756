const DisableFields = require('./../../../../../Resources/public/js/DisableFields');

(function ($, DisableFields) {
    var registerAgentSameAddress = $('#new_agent_sameAddress');
    if (registerAgentSameAddress.length) {
        new DisableFields(registerAgentSameAddress, [
            '#new_agent_correspondenceAddress_line1',
            '#new_agent_correspondenceAddress_line2',
            '#new_agent_correspondenceAddress_line3',
            '#new_agent_correspondenceAddress_town',
            '#new_agent_correspondenceAddress_postcode'
        ]);
    }
})(jQuery, DisableFields);

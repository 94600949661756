import 'babel-polyfill';
import { debounce } from '@zxcvbn-ts/core';

(function ($) {
    'use strict';

    // Because the zxcvbn library is huge it is not always loaded, if
    // unavailable skip performing password strength measurements.
    async function zxcvbn(password, userInputs) {
        if ('zxcvbn' in window) {
            return await window.zxcvbn(password, userInputs);
        }

        return {};
    }

    function getPassword() {
        return this.$input.val();
    }

    function setHelp(score, warning) {
        var value;

        value = 'Score: '+score+'/4';

        if (null !== warning && warning.length > 0) {
            value += ' ('+warning+')';
        }

        this.$help.text(value);
    }

    function PasswordStrength($input) {
        this.$input = $input;
        this.$target = $input.closest('.form-group');
        this.$help = $('<span>').addClass('help-block');
        this.$suggestions = $('<div>').addClass('form-group');
        this.$userInputs = $(this.$input.data('password-strength-inputs'));

        this.$target.after(this.$suggestions);
        this.$target.append(this.$help);
    }

    PasswordStrength.prototype.calculate = async function () {
        return await zxcvbn(getPassword.call(this), this.$userInputs.map(function () {
            return $(this).val();
        }).get());
    }

    PasswordStrength.prototype.getScore = async function () {
        return (await this.calculate()).score || 0;
    }

    PasswordStrength.prototype.reset = function () {
        this.$target.removeClass('has-warning');
        this.$target.removeClass('has-success');
        this.$target.removeClass('has-error');
        this.$suggestions.empty();
        this.$help.text('');
    }

    PasswordStrength.prototype.update = async function () {
        var result;
        var score;

        if (getPassword.call(this).length === 0) {
            this.reset();

            return;
        }

        result = await this.calculate();

        if (result.score === undefined) {
            return;
        }

        this.reset();

        setHelp.call(this, result.score, result.feedback.warning);

        if (result.feedback.suggestions.length > 0) {
            this.$suggestions.append(result.feedback.suggestions.map(function (value) {
                return $('<div>').text(value);
            }));
        }

        switch (result.score) {
            case 0:
            case 1:
                this.$target.addClass('has-error');
                break;
            case 2:
                this.$target.addClass('has-warning');
                break;
            case 3:
            case 4:
                this.$target.addClass('has-success');
                break;
        }
    }

    $('[data-password-strength]').each(async function () {
        var $this = $(this),
            passwordStrength = new PasswordStrength($this),
            $callout;

        $callout = $('<div>')
            .addClass('callout')
            .addClass('callout-danger')
            .text('Your chosen password is too weak to proceed.');

        await passwordStrength.update();

        $this.on('keyup', debounce(async function () {
            await passwordStrength.update();
        }, 50));

        $(this.form).on('submit', async function (e) {
            if (await passwordStrength.getScore() < 3) {
                e.preventDefault();

                $(this).prepend($callout);

                passwordStrength.$input.focus();
            }
        });
    });
})(jQuery);

(function ($) {
    'use strict';

    var $modal,
        $modalTitleHolder,
        $modalContentsHolder;

    $modal = $('.js-claim-statuses__modal');

    if ($modal.length === 0) {
        return;
    }

    $modalTitleHolder = $modal.find('.modal-title__holder');
    $modalContentsHolder = $modal.find('.modal-contents__holder');

    $('.js-claim-statuses__launch-modal').on('click', function (e) {
        e.preventDefault();
        var $this = $(this),
            $content = $this.next('.modal-contents'),
            $title = $content.next('.modal-title');

        $modalContentsHolder.empty().append($content.clone().removeClass('hide'));
        $modalTitleHolder.empty().append($title.clone().removeClass('hide'));

        $modal.modal();
    });
})(jQuery);

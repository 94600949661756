(function ($) {
    'use strict';

    var $noteBody = $('.js-note-body');

    $('.js-quick-response-notes').on('click', 'a', function () {
        $noteBody.val(
            $noteBody.val() + ' ' + $(this).text()
        );
        $noteBody.change();
    });
})(jQuery);

const DisableFields = require('./../../../../../../Resources/public/js/DisableFields'),
    ChangeViewFromChoice = require('./../../../../../../Resources/public/js/ChangeViewFromChoice');

(function ($, DisableFields, ChangeViewFromChoice) {
    'use strict';

    function closedCheckboxToggle($obj) {
        var id = $obj.attr('id').replace($obj.data('source'), $obj.data('target'));
        var $input = $('#'+id);
        if ($obj.is(':checked')) {
            $input.prop('disabled', true);
        } else {
            $input.prop('disabled', false);
        }
    }

    var $closedCheckboxes = $('.js-closed-checkbox');

    $.each($closedCheckboxes, function(key, value) {
        closedCheckboxToggle($(value));
    });

    $closedCheckboxes.on('click', function () {
        closedCheckboxToggle($(this));
    });

    var registerSettingSameAddress = $('#registration_setting_paper_setting_basicDetails_sameAddress');
    if (registerSettingSameAddress.length) {
        new DisableFields(registerSettingSameAddress, [
            '.js-registration_setting_paper_setting_basicDetails_correspondenceAddress-entry',
            '.js-registration_setting_paper_setting_basicDetails_correspondenceAddress-lookup',
            '#registration_setting_paper_setting_basicDetails_correspondenceAddress_line1',
            '#registration_setting_paper_setting_basicDetails_correspondenceAddress_line2',
            '#registration_setting_paper_setting_basicDetails_correspondenceAddress_line3',
            '#registration_setting_paper_setting_basicDetails_correspondenceAddress_town',
            '#registration_setting_paper_setting_basicDetails_correspondenceAddress_postcode'
        ]);
    }

    if ($('#registration_setting_paper_settingMilk_type').length) {
        new ChangeViewFromChoice('#registration_setting_paper_settingMilk_type input', [
            {'value':'SELF_PURCHASE', 'element':'#self-purchase'},
            {'value':'AGENT', 'element':'#agent-purchase'},
            {'value':'LOCAL_AUTHORITY', 'element':'#authority-purchase'}
        ]);
    }
})(jQuery, DisableFields, ChangeViewFromChoice);

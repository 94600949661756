require('../less/app.less');

require('jquery');
require('source-sans-pro/source-sans-pro.css');
require('bootstrap');
require('bootstrap/less/bootstrap.less');
require('admin-lte');
require('admin-lte/build/less/skins/skin-blue.less');
require('admin-lte/build/less/AdminLTE.less');
require('font-awesome/less/font-awesome.less');

require('./../../../../../Resources/public/js/globals');
require('./setting/paperRegister');
require('./setting/register');
require('./agentClaimFiles');
require('./agentRegistration');
require('./claimAuditEvidenceSaver');
require('./claimStatuses');
require('./cowHide');
require('./dossierAddNote');
require('./dossierAddSetting');
require('./editCertificate');
require('./missingPeriods');
require('./noteAttachment');
require('./noteSaver');
require('./noteSubmitter');
require('./noteUseReference');
require('./passwordStrength');
require('./pdfEmbed');
require('./quickResponse');
require('./searchClaims');
require('./searchSettings');
require('./sidebarPersistance');
require('./tableGrouping');

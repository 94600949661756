(function ($) {
    'use strict';

    $('tr.table--grouped').on('mouseenter', function () {
        var group = $(this).data('grouping');
        $('tr[data-grouping="'+group+'"]').addClass('table--group-highlight');
        $('tr[data-grouping="'+group+'"]:first').addClass('table--group-start');
        $('tr[data-grouping="'+group+'"]:last').addClass('table--group-end');
    }).on('mouseleave', function () {
        var group = $(this).data('grouping');
        $('tr[data-grouping="'+group+'"]').removeClass('table--group-highlight');
        $('tr[data-grouping="'+group+'"]:first').removeClass('table--group-start');
        $('tr[data-grouping="'+group+'"]:last').removeClass('table--group-end');
    });
})(jQuery);

require('./certificate');
require('./editSetting');
require('./formDisableSubmit');
require('./loginBanner');
require('./jquery.getAddress');
require('./postcodeLookup');
require('./purchases');
require('./registrationAttendance');
require('./sumTotal');
require('./tooltips');

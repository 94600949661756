(function ($) {
    'use strict';

    // Feature detect + local reference
    const storage = (function() {
        const uid = new Date;
        let storage;
        let result;
        try {
            (storage = window.localStorage).setItem(uid, uid);
            result = storage.getItem(uid) == uid;
            storage.removeItem(uid);
            return result && storage;
        } catch (exception) {}
    }());

    let submitted = false,
        timer = [],
        resetTimer = [];

    const $evidenceInputs = $('.js-attendance__number, .js-attendance__under-one, .js-purchase__cost'),
        icons = 'fa-spin fa-sticky-note-o fa-check fa-circle-o-notch',
        $jsEvidenceSaved = $('.js-evidence__saved'),
        $jsEvidenceSavedIcon = $jsEvidenceSaved.children('span.fa'),
        $jsEvidenceSavedMessage = $jsEvidenceSaved.children('span.msg');

        $evidenceInputs.each(function() {
            const $evidenceInput = $(this);

            if ($evidenceInput.val() == '' && storage.getItem($evidenceInput.attr('name')) != '') {
                return $evidenceInput.val(storage.getItem($evidenceInput.attr('name')));
            }
        });

        $evidenceInputs.each(function(index, value ) {
            const $evidenceInput = $(this);

            $evidenceInput.data('unsaved', false);
            $evidenceInput.on('change keyup', function() {
                if ($evidenceInput.val() == '') {
                    return;
                }

                $jsEvidenceSavedIcon.removeClass(icons).addClass('fa-circle-o-notch fa-spin');
                $jsEvidenceSaved.removeClass('bg-success text-success').addClass('text-muted');
                $jsEvidenceSavedMessage.text('…');

                clearTimeout(timer[index]);
                clearTimeout(resetTimer[index]);

                timer[index] = window.setTimeout(function(){
                    $evidenceInput.data('unsaved', true);
                    storeData($evidenceInput);
                    indicateStatus(index, $evidenceInput);
                }, 1000);
            });
        });

    window.addEventListener('beforeunload', function () {
        if (!submitted) {
            $evidenceInputs.each(function() {
                if (storeData($(this)) === false) {
                    return 'You have not saved your data, are you sure you wish to discard it?';
                }
            });
        }
    });

    $evidenceInputs.closest('form').not('.js-disable-clear-on-submit').on('submit', function(){
        submitted = true;

        $evidenceInputs.each(function(index, value) {
            storage.removeItem($(this).attr('name'));
        });
        clearTimeout(timer[index]);
        clearTimeout(resetTimer[index]);
    });

    function storeData(input) {
        if (input.data('unsaved') && storage) {
            storage.setItem(
                input.attr('name'),
                input.val()
            );
            return true;
        }
        return false;
    };

    function indicateStatus(index, input) {
        if (!storage || !input) {
            return;
        }

        $jsEvidenceSaved.toggleClass('bg-success text-success', input.data('unsaved')).toggleClass('text-muted', !input.data('unsaved'));

        if (input.data('unsaved')) {
            $jsEvidenceSavedIcon.removeClass(icons).addClass('fa-check');
            $jsEvidenceSavedMessage.text('Evidence saved');
            input.data('unsaved', false);
            resetTimer = window.setTimeout(function() {
                indicateStatus(index, input);
            }, 2000);
            return;
        }

        if (!input.data('unsaved')) {
            $jsEvidenceSavedIcon.removeClass(icons).addClass('fa-sticky-note-o');
            $jsEvidenceSavedMessage.text('No changes since last evidence save');
            return;
        }
    };
})(jQuery);

// Embeds PDFs onto the website
(function(PDFObject) {
    document.querySelectorAll('.js-pdfembed').forEach(elem => {
        const source = elem.dataset.source;

        if (source) {
            PDFObject.embed(source, elem, {
                fallbackLink: elem.innerHTML
            });
        }
    });
})(require('pdfobject'));

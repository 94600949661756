(function ($) {
    'use strict';

    var $claimStatus = $('.js-claim-search__status');
    var $claimProvider = $('.js-claim-search__provider');
    var $claimProviderOptions = $claimProvider.find('.setting-provider__agent, .setting-provider__local-authority');

    toggleFilters();

    $claimStatus.on('change', function() {
        toggleFilters();
    });

    function toggleFilters() {
        if ($claimStatus.val() === 'CANCELLED') {
            disableFilters();
        } else {
            enableFilters();
        }
    }

    function enableFilters() {
        $claimProviderOptions.prop('disabled', false);
        $claimProvider.tooltip('destroy');
    }

    function disableFilters() {
        var $selected = $claimProviderOptions.filter(':selected');

        $claimProvider.tooltip({
            'title': 'You cannot search by provider when status filter is set to cancelled.'
        });

        if ($selected.length > 0 && $selected.hasClass('setting-provider__agent')) {
            $claimProvider.val('AGENT');
            $claimProvider.tooltip('show');
        } else if ($selected.length > 0 && $selected.hasClass('setting-provider__local-authority')) {
            $claimProvider.val('LOCAL_AUTHORITY');
            $claimProvider.tooltip('show');
        }

        $claimProviderOptions.prop('disabled', true);
    }

})(jQuery);

(function ($) {
    'use strict';

    var $noteAllSettings = $('.js-dossier__note-all-settings'),
        $noteAllSettingsWarning = $('.js-dossier__note-all-settings-warning'),
        $relevantSetting = $('.js-dossier__relevant-setting');

    $noteAllSettings.on('change', function(){
        var state = $(this).is(':checked');

        $relevantSetting.toggleClass('disabled', state).prop('disabled', state);
        $noteAllSettingsWarning.toggleClass('hide', !state);
    });
})(jQuery);

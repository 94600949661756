const DisableFields = require('./DisableFields'),
    ConditionalDisableFields = require('./ConditionalDisableFields');

(function ($, DisableFields, ConditionalDisableFields) {
    'use strict';
    var $registerCheckbox = $('.js-certificate-not-received');
    var $registerNumberInput = $('.js-registration-number')
    if ($registerCheckbox.length) {
        new DisableFields($registerCheckbox, $registerNumberInput);
    }

    var $registrationAuthorityNone = $('.js-authority-options input[value="NONE"]');
    var $registrationAuthorityOther = $('.js-authority-options input[value!="NONE"]');
    if ($registrationAuthorityNone.length) {
        new ConditionalDisableFields($registrationAuthorityNone, $registrationAuthorityOther, $registerNumberInput.add($registerCheckbox));
    }

    $('.js-authority-options input').on('change', function(){
        const $currentChoice = $('.js-authority-options input:checked'),
            $registerNumberInput = $('.js-registration-number'),
            $registrationNumberLabel = $registerNumberInput.prev('label');

        $registerNumberInput.attr('placeholder', $currentChoice.data('registration-number-placeholder'));
        $registrationNumberLabel.text($currentChoice.data('registration-number-label'));

        // Trigger keyup to recheck inline validation
        $registerNumberInput.trigger('keyup');
    });

    $registerNumberInput.on('keyup blur', function() {
        var $this = $(this),
            $parent = $this.parent('.form-group'),
            $msg = $this.next('.help-block');

        if ($msg.length == 0) {
            $msg = $('<div>').addClass('help-block');
        }

        if ($this.val().length >= 2 && $this.val().match(/^RP/i) && $('.js-authority-options input:checked').val() === 'OFSTED') {
            $parent.addClass('has-error').append($msg.text($('.js-authority-options input:checked').data('registration-number-ofsted-not-rp')));
        } else {
            $parent.removeClass('has-error');
            $msg.remove();
        }
    })
})(jQuery, DisableFields, ConditionalDisableFields);

(function ($) {
    'use strict';

    var $settingProviderType = $('.js-setting-search__provider-type');
    var $settingProviderTypeOptions = $('.js-setting-search__provider-type option');
    var $settingRestrictions = $('.js-setting-search__restrictions');

    toggleFilters();

    $settingRestrictions.on('change', function() {
        toggleFilters();
    });

    function toggleFilters() {
        if ($settingRestrictions.val() === '1') {
            disableFilters();
        } else {
            enableFilters();
        }
    }

    function enableFilters() {
        $settingProviderTypeOptions.each(function() {
            $(this).prop('disabled', false);
        });
        $settingProviderType.tooltip('destroy');
    }

    function disableFilters() {
        $settingProviderTypeOptions.each(function() {
            $(this).prop('disabled', true);
        });

        $settingProviderType.val('');

        $settingProviderType.tooltip({
            'title': 'You cannot search by provider type when suspended filter is set to Access.'
        });
    }

})(jQuery);

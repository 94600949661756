const DisableFields = require('./../../../../../../Resources/public/js/DisableFields');

(function ($, DisableFields) {
    'use strict';

    var registerSettingSameAddress = $('#registration_setting_basicDetails_sameAddress');
    if (registerSettingSameAddress.length) {
        new DisableFields(registerSettingSameAddress, [
            '.js-registration_setting_basicDetails_correspondenceAddress-entry',
            '.js-registration_setting_basicDetails_correspondenceAddress-lookup',
            '#registration_setting_basicDetails_correspondenceAddress_line1',
            '#registration_setting_basicDetails_correspondenceAddress_line2',
            '#registration_setting_basicDetails_correspondenceAddress_line3',
            '#registration_setting_basicDetails_correspondenceAddress_town',
            '#registration_setting_basicDetails_correspondenceAddress_postcode'
        ]);
    }
})(jQuery, DisableFields);

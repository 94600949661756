(function ($) {
    'use strict';

    var keyMeta = document.querySelector('meta[name="addresslookup"]');

    if (null === keyMeta) {
        $('[data-postcode-lookup]').remove();
        return;
    }

    var key = keyMeta.content;

    // External JS has failed to load, remove the fields and halt further action
    if (!$.fn.getAddress || 0 === key.length) {
        $('[data-postcode-lookup]').remove();
        return;
    }

    if ($('[data-postcode-lookup]').length > 0)
    {
        $.get('https://api.getaddress.io/usage?api-key=' + key, function (data) {
            // Leave leeway of 50 API calls to consider concurrent loaded pages
            if (data.DailyRequestCount >= (data.DailyRequestLimit2 - 50)) {
                $('[data-postcode-lookup]').remove();
            } else {
                $('[data-postcode-lookup]').each(function () {
                    var $this = $(this);
                    var identifier = $this.data('postcode-lookup');
                    var inputClass = '.js-' + identifier + '-entry';
                    var buttonClass = '.js-' + identifier + '-lookup';

                    $this.addClass('in');

                    $this.find(inputClass).on('keydown', function (e) {
                        if (e.which === 13) {
                            e.preventDefault();

                            $this.find(buttonClass).click();
                        }
                    });

                    $this.getAddress({
                        input: inputClass,
                        button: buttonClass,
                        output_fields: {
                            line_1: '#' + identifier + '_line1',
                            line_2: '#' + identifier + '_line2',
                            line_3: '#' + identifier + '_line3',
                            post_town: '#' + identifier + '_town',
                            postcode: '#' + identifier + '_postcode'
                        },
                        api_key: key,
                        sort_numerically: true,
                        lookup_interval: 200, // The really long delay looks silly after search has completed
                        button_label: $this.find(buttonClass).text(), // Retain the existing label, no extra translation required
                        dropdown_select_message: $this.data('postcode-lookup-dropdown-select-message'),
                        button_disabled_message: $this.data('postcode-lookup-button-disabled-message'),
                        error_message_default: $this.data('postcode-lookup-error-message-default'),
                        error_message_postcode_not_found: $this.data('postcode-lookup-error-message-postcode-not-found'),
                        error_message_postcode_invalid: $this.data('postcode-lookup-error-message-postcode-invalid'),
                        dropdown_class: 'form-control postcode-lookup--spacer-top',
                        error_message_class: 'text-danger postcode-lookup--spacer-top',
                        error_message: 'text-danger postcode-lookup--spacer-top',
                        onLookupError: function () {
                            // Override error message if it is a server error.
                            if (/server/.test(this.$error_message.text())) {
                                this.setErrorMessage(this.error_message_default);
                            }
                        }
                    });
                });
            }
        });
    }
})(jQuery);

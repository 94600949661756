(function ($) {
    'use strict';
    var $control = $('.js-under-five-control');
    var $responders = $('.js-under-five-responder');

    if ($control.length) {
        $control.on('change', function(){
            $responders.attr('max', $control.val() * 2);
        });

        if ($control.val() > 0) {
            $control.trigger('change');
        }
    }
})(jQuery);
